import * as React from "react";
import '../../styles/login/layout.scss';

function LoginLayout({ children }) {
  return (
    <div className="login">
        <div className="login--header">
            <img src="https://www.lrfmedia.se/app/themes/careofhaus/dist/logo.svg"/>
        </div>
        <div className="login--wrapper">
            <div className="login--background"></div>
            <div class="login--container">
                { children }
            </div>
        </div>
        
    </div>
  );
}

export default LoginLayout;