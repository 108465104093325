import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import * as RegIcons from '@fortawesome/free-regular-svg-icons'
import '../../styles/dashboard/appItem.scss';
import SmallButton from "../buttons/small";

function AppListItem({ app, favorite, addFavorite, removeFavorite }) {
  return (
    <div className="apps-listitem">
        <div className="apps-listitem--name">
            <FontAwesomeIcon icon={Icons[app.icon]} />
            <p>{app.name}</p>
            <p className="description">{ app.description || '' }</p>
        </div>
        <div className="apps-listitem-actions">
            { favorite && (
                <div onClick={() => { removeFavorite(app.url) }} className="apps-listitem--favorite apps-listitem--favorite--active">
                    <FontAwesomeIcon icon={Icons.faStar} />
                </div>
            )}
            { !favorite && (
                <div onClick={() => { addFavorite(app.url) }} className="apps-listitem--favorite">
                    <FontAwesomeIcon icon={RegIcons.faStar} />
                </div>
            )}
            <SmallButton onClick={() => {
                window.location.href=`https://${app.url}.apps.lrfmedia.io`
            }} text="Gå till app"/>
        </div>
    </div>
  );
}

export default AppListItem;