import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LargeButton from "../components/buttons/large";
import InputText from "../components/inputs/input";
import LoginLayout from "../components/login/layout";
import BreakLine from "../components/other/breakline";
import Loader from "../components/other/loader";
import Message from "../components/other/message";
import LargeTitle from "../components/typography/largeTitle";
import SmallText from "../components/typography/smallText";

const BEARER = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlIjoic21zLXNlcnZpY2UtcHJvZHVjdGlvbiIsImlhdCI6MTY0MzM1ODAwNn0.BuNYTW1rzV7V2dkOHdQTb_vANQg24OD14kZP_4Tr2LE'

function SetPassword() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    const [formSent, setFormSent] = useState(false)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        password: {
            error: false,
            message: ''
        },
        passwordAgain: {
            error: false,
            message: ''
        }
    })
    const [messageInfo, setMessageInfo] = useState(null);
    const [credentials, setCredentials] = useState({
        password: '',
        passwordAgain: '',
        token: params?.token || ''
    })

    const setNewPassword = async () => {
        setMessageInfo(false);
        setLoading(true);
        const error = {
            password: {
                error: false,
                message: ''
            },
            passwordAgain: {
                error: false,
                message: ''
            }
        }
        if(credentials.password.length < 8) {
            error.password.error = true;
            error.password.message = 'Kräver 8 tecken eller längre'
        }
        if(credentials.passwordAgain !== credentials.password) {
            error.passwordAgain.error = true;
            error.passwordAgain.message = 'Ange samma lösenord i båda fälten'
        }

        if(!error.password.error && !error.passwordAgain.error) {

            try {
                const res = await axios.post('https://dashboard.apps.lrfmedia.io/setnewpassword', {
                    password: credentials.password,
                    token: credentials.token
                }, {
                    headers: {
                        authorization: BEARER
                    }
                });
                setLoading(false);
                if(res.data.msg === 'updated') {
                    setMessageInfo({ type: 'success', text: 'Nytt lösenord är skapat, skickas till inloggning om 5 sekunder' });
                    setTimeout(() => {
                        window.location.href = 'https://dashboard.apps.lrfmedia.io/login';
                    }, 5000);
                } else {
                    setMessageInfo({ type: 'error', text: 'Något gick snett, kontakta utvecklingsavdelningen' });
                }
            } catch (err) {
                setLoading(false);
                setMessageInfo({ type: 'error', text: 'Något gick snett, kontakta utvecklingsavdelningen' });
            }
        } else {
            setLoading(false);
            setError(error);
        }
    }

    return (
        <LoginLayout>
            <>
                <LargeTitle text="Ange nytt lösenord" />
                <InputText 
                    error={error.password}
                    label="Lösenord"
                    callToAction={false} 
                    value={credentials.password} 
                    onChange={(v) => {
                        setCredentials(prev => {
                            return {
                                ...prev,
                                password: v
                            }
                        })
                    }}
                    type="password"
                />
                <InputText 
                    error={error.passwordAgain}
                    label="Upprepa lösenord"
                    callToAction={false} 
                    value={credentials.passwordAgain} 
                    onChange={(v) => {
                        setCredentials(prev => {
                            return {
                                ...prev,
                                passwordAgain: v
                            }
                        })
                    }}
                    type="password"
                />
                <Loader visible={loading}/>
                <LargeButton
                    text="Skapa nytt lösenord"
                    onClick={() => setNewPassword()} 
                />
                { messageInfo && (
                    <Message message={messageInfo}/>
                )}
            </>
        </LoginLayout>
    );
}

export default SetPassword;