import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser } from '@fortawesome/free-regular-svg-icons'
import '../../styles/dashboard/profile.scss';

function Profile({ user }) {
  return (
    <div className="profile">
        <div className="profile-user">Hej {user.name.firstname}!</div>
        <div className="profile-options">
            <div className="profile-options--icon">
                <FontAwesomeIcon icon={faCircleUser} />
            </div>
            <div className="profile-options--dropdown">
                <div className="profile-options--dropdown--container">
                    <a href="/logout">Logga ut</a>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Profile;