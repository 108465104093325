import * as React from "react";
import '../../styles/components/button.scss'

function SmallButton({ text, onClick }) {
  return (
    <div 
        onClick={() => { onClick() }} 
        className="button--small" 
        dangerouslySetInnerHTML={{__html: text}}>    
    </div>
  );
}

export default SmallButton;