import * as React from "react";
import '../../styles/dashboard/apps.scss';
import BreakLine from "../other/breakline";
import MediumTitle from "../typography/mediumTitle";
import AppItem from "./appItem";

function Favorites({ apps }) {
  return (
    <div className="apps-favorites--container">
        <MediumTitle text="Favoriter"/>
        <div className="apps-favorites--items">
            { apps.map(a => (
                <AppItem app={a} />
            )) }
        </div>
    </div>
  );
}

export default Favorites;