import React, { useEffect, useState } from "react";
import moment from 'moment';
import AppsList from "../components/dashboard/appList";
import AppsContainer from "../components/dashboard/appsContainer";
import Favorites from "../components/dashboard/favorites";
import DashboardLayout from "../components/dashboard/layout";
import News from "../components/dashboard/news";
import Profile from "../components/dashboard/profile";
import SidebarContainer from "../components/dashboard/sidebarContainer";

function Home() {
    const [user, setUser] = useState({
        name: window.user.profile,
        user_id: window.user.user_id,
        favorites: JSON.parse(localStorage.getItem('favorites') || '[]')
    });
    const [news, setNews] = useState([
        {
            title: 'Ny uppdatering i LRF Media apps dashboard',
            date: moment('2023-01-16'),
            text: `Ny uppdatering i dashboard, nu kan man favorit markera appar och
            enkelt hitta de applikationer man har tillgång till.
            Vi från utvecklingsteamet kommer även skriva uppdateringar som rör
            alla interna applikationer här.`
        }
    ]);
    const [apps, setApps] = useState(window.apps.map(app => {
        const accessApps = Object.keys(window.user.access);
        return {
            name: app.name,
            url: app.url,
            title: app.title,
            shortcode: app.shortcode,
            icon: app.icon,
            color1: app.color1,
            color2: app.color2,
            access: accessApps.includes(app.url) ? true : false,
        }
    }))
    const updateFavorites = async (newFavorites) => {
        localStorage.setItem('favorites', JSON.stringify(newFavorites))
    }
    return (
        <>
            <DashboardLayout>
                <AppsContainer>
                    <Favorites apps={apps.filter(a => user.favorites.includes(a.url))}/>
                    <AppsList 
                        apps={apps} 
                        favorites={user.favorites} 
                        addFavorite={(url) => {
                            const newFavorites = user.favorites;
                            newFavorites.push(url);
                            setUser(prev => {
                                return {
                                    ...prev,
                                    favorites: newFavorites
                                }
                            })
                            updateFavorites(newFavorites);
                        }}
                        removeFavorite={(url) => {
                            const newFavorites = user.favorites.filter(d => d !== url);
                            setUser(prev => {
                                return {
                                    ...prev,
                                    favorites: newFavorites
                                }
                            })
                            updateFavorites(newFavorites);
                        }}
                    />
                </AppsContainer>
                <SidebarContainer>
                    <Profile user={user}/>
                    <News news={news}/>
                </SidebarContainer>
            </DashboardLayout>
        </>
    );
}

export default Home;

