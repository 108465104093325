import * as React from "react";
import '../../styles/dashboard/sidebar.scss';

function SidebarContainer({ children }) {
  return (
    <div className="sidebar--container">
        { children }
    </div>
  );
}

export default SidebarContainer;