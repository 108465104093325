import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import '../../styles/dashboard/appItem.scss';

function AppItem({ app }) {
  return (
    <a href={`https://${app.url}.apps.lrfmedia.io`}>
        <div className="apps--item">
            <div className="apps--item--inner">
                <FontAwesomeIcon icon={Icons[app.icon]} />
                <p>{app.name}</p>
            </div>
        </div>
    </a>
  );
}

export default AppItem;