import * as React from "react";
import '../../styles/components/input.scss'

function InputText({ label, callToAction, value, onChange, type, error }) {
  return (
    <div className={`input--container ${error.error ? 'input--container__invalid' : 'input--container__valid'}`}>
        { callToAction && (
            <div className="input--cta" onClick={() => { callToAction.onClick() }}>{ callToAction.text }</div>
        )}
        <label className="input--label">
            { label }
            <input type={type} value={ value } onChange={(ele) => { onChange(ele.target.value) }}></input>
            {error.error && (
              <p className="input--label--error">{error.message}</p>
            )}
        </label>
    </div>
  );
}

export default InputText;