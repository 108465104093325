import * as React from "react";
import '../../styles/dashboard/layout.scss';

function DashboardLayout({ children }) {
  return (
    <div className="dashboard">
        { children }
    </div>
  );
}

export default DashboardLayout;