import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LargeButton from "../components/buttons/large";
import InputText from "../components/inputs/input";
import LoginLayout from "../components/login/layout";
import BreakLine from "../components/other/breakline";
import LargeTitle from "../components/typography/largeTitle";
import SmallText from "../components/typography/smallText";
import Loader from "../components/other/loader";

const BEARER = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlIjoic21zLXNlcnZpY2UtcHJvZHVjdGlvbiIsImlhdCI6MTY0MzM1ODAwNn0.BuNYTW1rzV7V2dkOHdQTb_vANQg24OD14kZP_4Tr2LE'

function Login() {
    const navigate = useNavigate();
    const [screen, setScreen] = useState('login');
    const [codeSent, setCodeSent] = useState(false);
    const [sentCodeLogin, setSentCodeLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        email: {
            error: false,
            message: ''
        },
        password: {
            error: false,
            message: ''
        },
        sms: {
            error: false,
            message: ''
        }
    })
    const [credentials, setCredentials] = useState({
        email: '',
        password: '',
        smsCode: ''
    })

    const signIn = async () => {
        const error = {
            email: {
                error: false,
                message: ''
            },
            password: {
                error: false,
                message: ''
            },
            sms: {
                error: false,
                message: ''
            }
        }
        if(credentials.email.length < 5) {
            error.email.error = true;
            error.email.message = 'Email krävs'   
        }
        if(credentials.password.length < 3) {
            error.password.error = true;
            error.password.message = 'Lösenord krävs'   
        }

        if(!error.email.error && !error.password.error && !codeSent) {
            setLoading(true);
            setCodeSent(true);
            try {
                const res = await axios.post('https://dashboard.apps.lrfmedia.io/login', {
                    email: credentials.email,
                    password: credentials.password
                }, {
                    headers: {
                        authorization: BEARER
                    }
                });
                if(res?.data?.msg === 'sent') {
                    setLoading(false);
                    setScreen('tfa')
                } else if (res?.data?.msg === 'failed') {
                    setLoading(false);
                    setCodeSent(false);
                    error.email.error = true;
                    error.email.message = 'Fel uppgifter' 
                    error.password.error = true;
                    error.password.message = 'Fel uppgifter' 
                    setError(error);
                } else {
                    setCodeSent(false);
                }
            } catch (err) {
                setCodeSent(false);
                setLoading(false);
                error.email.error = true;
                error.email.message = 'Fel uppgifter' 
                error.password.error = true;
                error.password.message = 'Fel uppgifter' 
                setError(error);
            }
        } else {
            setError(error);
        }
    }

    const verify = async () => {
        if(codeSent) {
            const error = {
                email: {
                    error: false,
                    message: ''
                },
                password: {
                    error: false,
                    message: ''
                },
                sms: {
                    error: false,
                    message: ''
                }
            }
            if(credentials.smsCode.length !== 6) {
                error.sms.error = true;
                error.sms.message = 'Skriv giltig kod'
            }

            if(!error.sms.error && !sentCodeLogin) {
                setLoading(true);
                setSentCodeLogin(true);
                try {
                    const res = await axios.post('https://dashboard.apps.lrfmedia.io/login', {
                        email: credentials.email,
                        password: credentials.password,
                        token: credentials.smsCode
                    }, {
                        headers: {
                            authorization: BEARER
                        }
                    });

                    if(res?.data?.msg === 'login') {
                        setLoading(false);
                        window.location.href = 'https://dashboard.apps.lrfmedia.io/';
                    } else if (res?.data?.msg === 'failed') {
                        setLoading(false);
                        error.sms.error = true;
                        error.sms.message = 'Fel kod' 
                        setError(error);
                        setCodeSent(false);
                    } else {
                        setLoading(false);
                        setScreen('login');
                        setCodeSent(false);
                    }
                } catch (err) {
                    setTimeout(() => {
                        setLoading(false);
                        setScreen('login');
                    }, 2000);
                }

            } else {
                setError(error);
            }
        } else {
            setScreen('login')
        }
    }

    return (
        <LoginLayout>
            { screen === 'login' && (
                <>
                    <LargeTitle text="Välkommen!<br/>Logga in nedan" />
                    <InputText
                        error={error.email}
                        label="Email"
                        callToAction={false} 
                        value={credentials.email} 
                        onChange={(v) => {
                            setCredentials(prev => {
                                return {
                                    ...prev,
                                    email: v
                                }
                            })
                        }}
                        type="text"
                    />
                    <InputText 
                        error={error.password}
                        label="Lösenord"
                        callToAction={{
                            text: 'Glömt lösenord?',
                            onClick: () => {
                                navigate("/forgotpassword");
                            }
                        }} 
                        value={credentials.password} 
                        onChange={(v) => {
                            setCredentials(prev => {
                                return {
                                    ...prev,
                                    password: v
                                }
                            })
                        }}
                        type="password"
                    />
                    <Loader visible={loading}/>
                    <LargeButton
                        text="Logga in"
                        onClick={() => signIn()} 
                    />
                </>
            )}
            { screen === 'tfa' && (
                <>
                <LargeTitle text="Verifiera dig med engångskod" />
                <InputText 
                    error={error.sms}
                    label="SMS kod"
                    callToAction={false} 
                    value={credentials.smsCode} 
                    onChange={(v) => {
                        setCredentials(prev => {
                            return {
                                ...prev,
                                smsCode: v
                            }
                        })
                    }}
                    type="text"
                />
                <Loader visible={loading}/>
                <LargeButton
                    text="Verifiera"
                    onClick={() => verify()} 
                />
                <BreakLine/>
                <SmallText text="Får du ingen sms kod? Kontakta utvecklingsavdelningen
eller din närmaste chef."/>
            </>
            )}
        </LoginLayout>
    );
}

export default Login;