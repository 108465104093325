import * as React from "react";
import '../../styles/components/loader.scss'

function Loader({ visible }) {
    if(!visible) return null;
    return (
        <div className="loader-login--container"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
    );
}

export default Loader;