import * as React from "react";
import moment from 'moment';
import '../../styles/dashboard/news.scss';

function News({ news }) {
    return (
        <div className="news">
            <h4>Senaste uppdateringar</h4>
            <div className="news-items">
                <div className="news-item">
                    {news.map(n => (
                        <>
                            <p className="news-item--date">{ n.date.format('DD MMM YYYY') }</p>
                            <h3>{ n.title }</h3>
                            <p>{ n.text }</p>
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default News;