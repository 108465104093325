import * as React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgotpassword";
import SetPassword from "./pages/setpassword";
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/setpassword" element={<SetPassword />} />
      </Route>
    </Routes>
    </>
  );
}

function Layout() {
  return (
    <Outlet/>
  )
}

export default App;
