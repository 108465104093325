import * as React from "react";
import '../../styles/components/message.scss'

function Message({ message }) {
    return (
        <div className={`message--container message--container--${message.type}`}>
            {message.text}
        </div>
    );
}

export default Message;