import * as React from "react";
import '../../styles/dashboard/apps.scss';
import BreakLine from "../other/breakline";
import MediumTitle from "../typography/mediumTitle";
import AppListItem from "./appListItem";

function AppsList({ apps, favorites, addFavorite, removeFavorite }) {
  return (
    <div className="apps-list--container">
        <MediumTitle text="Alla dina applikationer"/>
        <div className="apps-list--items">
            {apps.map(a => (
                <>
                    {a.access && (
                        <AppListItem app={a} favorite={ favorites.includes(a.url) } addFavorite={addFavorite} removeFavorite={removeFavorite} />
                    )}
                </>
            ))}
        </div>
    </div>
  );
}

export default AppsList;