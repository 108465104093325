import * as React from "react";
import '../../styles/dashboard/apps.scss';

function AppsContainer({ children }) {
  return (
    <div className="apps--container">
        { children }
    </div>
  );
}

export default AppsContainer;