import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LargeButton from "../components/buttons/large";
import InputText from "../components/inputs/input";
import LoginLayout from "../components/login/layout";
import BreakLine from "../components/other/breakline";
import Loader from "../components/other/loader";
import Message from "../components/other/message";
import LargeTitle from "../components/typography/largeTitle";
import SmallText from "../components/typography/smallText";

const BEARER = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlIjoic21zLXNlcnZpY2UtcHJvZHVjdGlvbiIsImlhdCI6MTY0MzM1ODAwNn0.BuNYTW1rzV7V2dkOHdQTb_vANQg24OD14kZP_4Tr2LE'

function ForgotPassword() {
    const navigate = useNavigate();
    const [formSent, setFormSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        email: {
            error: false,
            message: ''
        }
    })
    const [messageInfo, setMessageInfo] = useState(null);
    const [credentials, setCredentials] = useState({
        email: ''
    })

    const forgotPassword = async () => {
        setMessageInfo(false);
        setLoading(true);
        const error = {
            email: {
                error: false,
                message: ''
            }
        }
        if (credentials.email.length < 5) {
            error.email.error = true;
            error.email.message = 'Skriv giltig email'
        }

        if (!error.email.error) {
            try {
                const res = await axios.post('https://dashboard.apps.lrfmedia.io/forgotpassword', {
                    email: credentials.email,
                }, {
                    headers: {
                        authorization: BEARER
                    }
                });
                setLoading(false);
                setMessageInfo({ type: 'success', text: 'Vi har skickat ett mail till dig för vidare instruktion' });
            } catch (err) {
                setLoading(false);
                setMessageInfo({ type: 'error', text: 'Något gick snett, kontakta utvecklingsavdelningen' });
            }

        } else {
            setLoading(false);
            setError(error);
        }
    }

    return (
        <LoginLayout>
            <>
                <LargeTitle text="Glömt lösenord" />
                <InputText
                    error={error.email}
                    label="Email"
                    callToAction={false}
                    value={credentials.email}
                    onChange={(v) => {
                        setCredentials(prev => {
                            return {
                                email: v
                            }
                        })
                    }}
                    type="text"
                />
                <Loader visible={loading}/>
                <LargeButton
                    text="Glömt lösenord"
                    onClick={() => forgotPassword()}
                />
                { messageInfo && (
                    <Message message={messageInfo}/>
                )}
            </>
        </LoginLayout>
    );
}

export default ForgotPassword;